<template>
  <div class="map-wrapper">
    <div id="map" class="map" :class="{ 'ol-layer-blur': enableBlurMap }"></div>
  </div>
</template>

<script>
import Vue from "vue";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Tile as TileLayer } from "ol/layer";
import { XYZ as XYZSource } from "ol/source";
import mapControl from "../../lib/map-controller";
import { fromLonLat } from "ol/proj";

export default {
  name: "map-view",
  props: {
    enableBlurMap: { type: Boolean, default: false },
    allowZoom: { type: Boolean, default: true }
  },
  mounted() {
    const source = new XYZSource({
      url: `${process.env.VUE_APP_MAPBOX_URL}${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`
    });

    const raster = new TileLayer({
      name: "map-center-crop-layer",
      source
    });

    const layers = [raster];
    const container = document.getElementById("map");

    this.map = new Map({
      layers: layers,
      target: container,
      controls: []
    });

    mapControl.setZoomEnable(this.map, this.allowZoom);

    const viewMap = new View({
      center: fromLonLat([
        process.env.VUE_APP_LNG_CENTER,
        process.env.VUE_APP_LAT_CENTER
      ]),
      zoom: process.env.VUE_APP_ZOOM_LEVEL
    });

    this.map.setView(viewMap);

    //assign the map to global vue instance
    Vue.prototype.$map = this.map;
  }
};
</script>

<style lang="scss" scoped>
@import "~ol/ol.css";

.map {
  height: 100%;
  background: #f8f4f0;
}
</style>
