<template>
  <div class="annotate">
    <v-container fill-height fluid pa-0 align-start>
      <v-row no-gutters class="top-toolbar">
        <v-col>
          <v-toolbar elevation="0">
            <v-toolbar-title />
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row no-gutters class="content-row">
        <v-col>
          <router-view
            name="center-content"
            class="router-view-container"
          ></router-view>
          <div class="map-view-container map-container">
            <map-view-basic :allowZoom="false" :enableBlurMap="true" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MapViewBasic from "@/components/map/MapViewBasic.vue";

export default {
  name: "annotate",
  components: { MapViewBasic },
  mounted() {
    this.$store.dispatch("session/resetSession");
  }
};
</script>

<style lang="scss" scoped>
.annotate {
  background: green;
}
.content-row {
  position: relative;
}

.map-view-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
</style>
